import React from "react";

const Header = () => {
  return (
    <div className="header">
      <img
        id="header-logo"
        src={require("../assets/kiiltäväx_white_cropped.png")}
        alt="Kiiltäväx Logo"
      />
    </div>
  );
};

export default Header;
