import React from "react";
import Header from "./Header";
import Introduction from "./Introduction";
import Footer from "./Footer";
import ServiceIcons from "./ServiceIcons";
import SecondaryIntroduction from "./SecondaryIntroduction";
import ContactSection from "./ContactSection";

const Layout: React.FC = () => {
  return (
    <>
      <div id="hero-image">
        <Header />
        <div className="layout">
          <Introduction />
        </div>
      </div>
      <ServiceIcons />
      <SecondaryIntroduction />

      <ContactSection />
      <Footer />
    </>
  );
};

export default Layout;
