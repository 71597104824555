const Introduction = () => {

  const handleContactClick = () => {
    const element = document.getElementById("contact-form-wrapper");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="intro-wrapper">
      <h1 className="intro-text">
        Meiltä saat tarvitsemasi siivouspalvelut yrityksille ja kotitalouksille
        pääkaupunkiseudulla!
      </h1>
      <p className="intro-contact-text" onClick={handleContactClick}>
        Ota yhteyttä
      </p>
    </div>
  );
};

export default Introduction;
