import React from "react";

interface Props {
  text: string;
}

const CheckmarkText = (props: Props) => {
  const { text } = props;
  return (
    <div className="checkmark-wrapper">
      <img
        className="checkmark-image"
        src={require("../assets/check-mark.png")}
        alt="checkmark"
      />
      <p className="checkmark-text">{text}</p>
    </div>
  );
};

export default CheckmarkText;
