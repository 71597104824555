import React from "react";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  return (
    <div className="contact-section-wrapper">
      <div className="contact-section-info">
        <h5 className="contact-section-item">Kiiltäväx Oy (3194515-4)</h5>
        <h5 className="contact-section-item">Lentäjäntie 3 G 38</h5>
        <h5 className="contact-section-item">03100 Nummela</h5>
        <h5 className="contact-section-item">myynti@kiiltavax.fi</h5>
        <h5 className="contact-section-item">+358 503378144</h5>
      </div>
      <ContactForm />
    </div>
  );
};

export default ContactSection;
