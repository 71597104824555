import { useState } from "react";
import emailjs from "@emailjs/browser";
import { differenceInMinutes } from "date-fns";

interface ContactMessage {
  tel: string;
  email: string;
  message: string;
}

const ContactForm = () => {
  const [contactMessage, setContactMessage] = useState<ContactMessage>({
    tel: "",
    email: "",
    message: "",
  });

  const lastMessageSentDate = localStorage.getItem("kiiltäväx-message")
    ? localStorage.getItem("kiiltäväx-message")
    : null;

  const lastMessageHas15MinDifference = lastMessageSentDate
    ? differenceInMinutes(new Date(), new Date(lastMessageSentDate)) > 1
    : true;

  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const enableSendButton =
    (contactMessage.tel || contactMessage.email) && contactMessage.message;

  const handleChange = (key: keyof ContactMessage, value: string) => {
    setContactMessage({
      ...contactMessage,
      [key]: value,
    });
  };

  const handleSubmitClick = () => {
    setIsSendingMessage(true);
    try {
      emailjs
        .send(
          "service_crvfd0q",
          "template_raxtnyo",
          contactMessage as unknown as Record<string, unknown>,
          "MnZpAZayueuL2SuaS"
        )
        .then(() => {
          setMessageSent(true);
          setIsSendingMessage(false);
          localStorage.setItem("kiiltäväx-message", String(new Date()));
        });
    } catch {
      setMessageSent(false);
      setIsSendingMessage(false);
      setMessageError(true);
    }
  };

  return (
    <div id="contact-form-wrapper">
      <h3 id="contact-form-title">Ota yhteyttä!</h3>
      <p id="contact-form-subtitle">Täytä kentät ennen lähettämistä</p>
      <input
        className="contact-form-input"
        type="text"
        placeholder="Puhelin"
        required
        onChange={(e) => handleChange("tel", e.target.value)}
      />
      <p id="contact-form-subtitle">tai</p>
      <input
        className="contact-form-input"
        type="text"
        placeholder="Sähköposti"
        required
        onChange={(e) => handleChange("email", e.target.value)}
      />
      <textarea
        required
        className="contact-form-input contact-form-message-input"
        placeholder="Kirjoita viestisi tähän"
        onChange={(e) => handleChange("message", e.target.value)}
      />
      {messageError ? (
        <p id="message-info-text">
          Viestin lähetys epäonnistui. Ottakaa yhteyttä puhelimitse tai
          sähköpostitse. Pahoittelut häiriöstä.
        </p>
      ) : !lastMessageHas15MinDifference || messageSent ? (
        <p id="message-info-text"> Viesti lähetetty!</p>
      ) : isSendingMessage ? (
        <p id="message-info-text">Lähetetään viestiä...</p>
      ) : (
        <button
          className="send-button"
          onClick={handleSubmitClick}
          disabled={!enableSendButton}
        >
          Lähetä
        </button>
      )}
    </div>
  );
};

export default ContactForm;
