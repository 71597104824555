import React from "react";
import CheckmarkText from "./CheckmarkText";

const checkmarkTexts = [
  {
    text: "Olemme 100% kotimainen yritys",
  },
  {
    text: "Keskitymme laatuun ja lopputulokseen",
  },
  {
    text: "Saat yksilöllistä siivouspalvelua tarpeittesi mukaan",
  },
];

const SecondaryIntroduction = () => {
  return (
    <div className="secondary-intro-wrapper">
      <p className="secondary-intro-text">Miksi valita meidät?</p>
      <div className="secondary-intro-checkmark-container">
        {checkmarkTexts.map((texts, i) => (
          <CheckmarkText text={texts.text} key={`checkmark-text-${i}`} />
        ))}
      </div>
    </div>
  );
};

export default SecondaryIntroduction;
